<template>
  <div class="mod-home">
    <h3>品质所服务信息采集小程序后台管理系统欢迎您！</h3>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-card title="">
            <div slot="header" class="clearfix">
              <span>统计概览</span>
            </div>
            <el-tag class="card-num">总用户数：{{allUser}}</el-tag>
            <el-tag class="card-num">总服务次数：{{allServeNum}}</el-tag>
            <el-tag class="card-num">总服务人次数：{{allServeObjNum}}</el-tag>
            <hr />
<!--            <div v-for="o in serveInfo" :key="o.uid" class="text item">-->
<!--              {{o.uname+'，服务次数：'+o.serveNum+'，服务人次：'+o.serveObjNum }}-->
<!--            </div>-->
            <serve-info-chart :serveInfo="serveInfo"/>
          </el-card>
          <hr />
          <el-col :span="24">
            <h2>根据所属单位统计</h2>
            <org-info-chart :type="0" />
          </el-col>
          <el-col :span="24">
            <h2>根据所属种子库(圃)统计</h2>
            <org-info-chart :type="1" />
          </el-col>
        </el-col>
        <el-col :span="24" style="margin-top: 10px;">
         <el-card header="最近服务信息">
           <recent-serve-content />
         </el-card>
        </el-col>
      </el-row>
  </div>
</template>

<script>
import RecentServeContent from './components/recent-servecontent'
import ServeInfoChart from './components/serve-info-chart'
import OrgInfoChart from './components/org-info-chart'
export default {
  components: {
    RecentServeContent,
    ServeInfoChart,
    OrgInfoChart
  },
  data() {
    return {
      allUser: 0,
      allServeNum:0,
      allServeObjNum: 0,
      serveInfo: [],
    }
  },
  created() {
    this.getStatisticData();
  },
  methods: {
    getStatisticData() {
      //这里现在统计的人数比较少还可以展示，但是人数太多之后，就会数据比较多而且图显示会比较拥挤
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/statistics/all'),
        method: 'get',
      }).then(({data}) => {
        this.allUser = data.serveUserStatis.serveUserTotal
        this.serveInfo = [];
        data.serveInfoStatis.forEach(s => {
          this.serveInfo.push(s);
          this.allServeNum += s.serveNum
          this.allServeObjNum += s.serveObjNum
        })
      })
    },
  }
}
</script>

<style>
.mod-home {
  line-height: 1.5;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}

.box-card {
  width: 480px;
}
.card-num {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>

